import { MailIcon, MapIcon } from "@heroicons/react/outline";
import { PhoneIcon } from "@heroicons/react/solid";
import { SiFacebook, SiTwitter, SiInstagram, SiLinkedin } from "react-icons/si";
import React from "react";
import Logo from "../../assets/image/logo-reverse.svg";

export default function Footer({ handleClick }) {
  return (
    <div className="bg-green flex flex-col items-center text-center space-y-4 lg:space-y-0 lg:flex-row lg:justify-evenly py-10 ">
      <div
        onClick={() => handleClick(0)}
        className="cursor-pointer hover:opacity-90 transition ease-in-out duration-200 hover:scale-110"
      >
        <img className="w-64" alt="" src={Logo} />
      </div>
      <div className="flex flex-col align-middle text-lg">
        <h2 className="text-3xl font-bold mb-2">Socials</h2>
        <div
          onClick={() => window.open("https://www.instagram.com/zelectricnl/")}
          className="flex space-x-2  items-center cursor-pointer hover:text-green-100 transition ease-in-out duration-200 hover:scale-110"
        >
          <SiInstagram />
          <p>Instagram</p>
        </div>
        <div
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=100085568697565"
            )
          }
          className="flex space-x-2  items-center cursor-pointer hover:text-green-100 transition ease-in-out duration-200 hover:scale-110"
        >
          <SiFacebook />
          <p>Facebook</p>
        </div>

        {/*<div
          onClick={() => window.open("https://twitter.com/")}
          className="flex space-x-2  items-center cursor-pointer hover:text-green-100 transition ease-in-out duration-200 hover:scale-110"
        >
          <SiTwitter />
          <p>Twitter</p>
          
        </div>*/}
        <div
          onClick={() =>
            window.open("https://www.linkedin.com/company/zelectric/")
          }
          className="flex space-x-2  items-center cursor-pointer hover:text-green-100 transition ease-in-out duration-200 hover:scale-110"
        >
          <SiLinkedin />
          <p>LinkedIn</p>
        </div>
      </div>
      <div className="flex flex-col align-middle text-lg">
        <h2 className="text-3xl font-bold mb-2">Contact</h2>

        {/* map icon and on click open google maps */}
        <div
          onClick={() =>
            window.open("https://goo.gl/maps/W5Rmk2Z38zRAma278", "")
          }
          className="flex space-x-2 items-center cursor-pointer hover:text-green-100 transition ease-in-out duration-200 hover:scale-110"
        >
          <MapIcon className="w-8" />
          <h3>Haringvliet 623, Rotterdam</h3>
        </div>

        {/* phone icon and on click call */}
        <div
          onClick={() => window.open("tel:+310683448003")}
          className="flex space-x-2 items-center cursor-pointer hover:text-green-100 transition ease-in-out duration-200 hover:scale-110"
        >
          <PhoneIcon className="w-8" />
          <h3>06-83448003</h3>
        </div>

        {/* email icon and on click create email */}
        <div
          onClick={() => window.open("mailto:info@zelectric.nl")}
          className="flex space-x-2 items-center cursor-pointer hover:text-green-100 transition ease-in-out duration-200 hover:scale-110"
        >
          <MailIcon className="w-8" />
          <h3>info@zelectric.nl</h3>
        </div>
      </div>
    </div>
  );
}
