import "./App.css";
import Header from "./components/Header/Header";
import Products from "./components/Products/Products";
import Process from "./components/Process/Process";
import Installations from "./components/Installation/Installations";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Form from "./components/Form/Form";
import { MainProduct } from "./components/MainProduct/MainProduct";
import { ContactForm } from "./components/ContactForm/ContactForm";
import { Pdf } from "./components/Pdf/Pdf";
import Testimonials from "./components/Testimonials/Testimonials";
import Nl from "./assets/image/icons/Nl.svg";
import { useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useParams,
  Navigate,
} from "react-router-dom";
import { Partners } from "./components/Partners/Partners";
import { Solution } from "./components/Solution/Solution";
import { AboutCompany } from "./components/AboutCompany/AboutCompany";

const webTranslation = [
  {
    name: "NL",
    header: {
      option1: "Over Ons",
      option2: "Installaties",
      option3: "Offerte",
    },
    hero: {
      heading: (
        <>
          Uw partner voor
          <span className="text-[#29C931]"> electrische </span>
          laad oplossingen
        </>
      ),
    },
    partners: {
      title: "Onze beste keuze",
    },
    solution: {
      title: "Laadpaal oplossingen voor",
      solution1: {
        title: "Prive Thuis",
        description:
          "Onze experts in het opladen van elektrische auto's hebben laadboxen en accessoires van gerenommeerde leveranciers geselecteerd die geschikt zijn voor privéwoningen voor zowel hybride auto's als elektrische auto's. Zelectric werkt nauw samen met Wallbox, Charge Amps, Easee, en EV Box, die volgens onze eigen tests de beste oplaadboxen en accessoires op de markt bieden voor het opladen van elektrische auto's. Zelectric biedt een complete installatie van laadboxen en laadpalen door gecertificeerde elektriciens. Onze focus is dat u als klant zich veilig en tevreden voelt!",
      },
      solution2: {
        title: "Bedrijf",
        description:
          "Zelectric heeft de meest populaire en oplaadboxen geselecteerd die geschikt zijn voor bedrijven. Deze modellen laadboxen van Wallbox, Easee, EvBox, Charge Amps werken uitstekend voor zowel hybride auto's als elektrische auto's en zijn toekomstbestendig voor elektrische auto's. Bovenal is het belangrijk om in gedachten te houden dat als je vandaag hybride auto's rijdt, het belangrijk is om een laadbox te installeren die ook optimaal is voor schone elektrische auto's wanneer je de volgende keer van auto wisselt. Wij bieden bedrijven een totaaloplossing met laadbox en installatie tegen een vaste prijs.",
      },
      solution3: {
        title: "VvE",
        description:
          "Laadboxen en laadoplossingen voor bedrijven en vastgoedeigenaren. De energieconversie in Nederland naar fossielvrij is inmiddels gaande. Bedrijven en vastgoedeigenaren moeten tegenwoordig overstappen en hun medewerkers en huurders oplaadmogelijkheden bieden voor hybride en elektrische autos.",
      },
    },
    aboutCompany: {
      title: "Kwaliteit en duurzaamheid staan bij ons centraal",
      option1: {
        title: "Over Ons",
        description:
          "De basis van onze bedrijfsvoereing is een duurzame toekomst. Wij zijn constant op zoek naar inovatieve manieren om verspilling te voorkomen en om ervoor te zorgen dat wij het maatschappij in een meer duurzame richting sturen. Zelectric is het juiste keuze voor u als u belang stelt aan een snelle service en levering, klantvriendelijkheid en kwaliteit.",
      },
      option2: {
        title: "Onze Missie",
        description:
          "Elektromobiliteit is de sleutel tot een fossielvrij en vervuiling vrij aards milieu. We dragen met onze kennis, ervaring en bewustzijn bij aan de transitie naar een duurzame toekomst met emissievrije elektrische voertuigen. Zelectric-missies zijn rationeel gebruik van energiebronnen door waar mogelijk groene technologie en geavanceerde technische oplossingen te implementeren.",
      },
      option3: {
        title: "Onze Visie",
        description:
          "De Europese auto-industrie maakt een technologische revolutie door en stapt over van fossiele brandstoffen naar groene elektromobiliteit. Zelectric wil deze transitie naar elektrificatie en naar een duurzamere omgeving helpen ondersteunen door gebruik te maken van de juiste business modellen en technische oplossingen.",
      },
    },
    process: {
      title: "Dit is hoe het werkt",
      point1: {
        title: "Raadpleeg onze Powerpoint",
        description:
          "U kunt onze powerpoint raadplegen om het installatie proces nog makkelijker te maken",
      },
      point2: {
        title: "Bestelling plaatsen",
        description: "Kies een van onze pakketen of laat ons voor u kiezen!",
      },
      point3: {
        title: "Wij boeken een afspraak voor u",
        description: "We gaan u contacteren en de installatie boeken",
      },
      point4: {
        title: "Installatie",
        description:
          "Onze installateurs komen langs en gaan in overleg met u de box installeren",
      },
    },
    contact: {
      title: "Wij helpen u de juiste keuze maken",
      option1: "Oplaadbox die bij je past",
      option2: "Aftrek voor groene technologie",
      option3: "Uitstekende service!",
    },
    offerte: {
      title: "Installatie aanvragen",
      heading: "Laat je adviseren door onze experts",
      heading2:
        "Wij zijn op maandag t/m zondag 8:00 tot 18:00 telefonisch bereikbaar. Wij proberen uw Whatsapp berichten, mailtjes en telefoontjes zo snel mogelijk te beantwoorden!",
      info: {
        title1: "Persoonlijke gegevens",
        firstName: "Voornaam",
        lastName: "Achternaam",
        email: "E-Mail",
        tel: "Telefoon",
        title2: "Adresgegevens",
        companyName: "Naam bedrijf",
        streetName: "Straatnaam",
        houseNr: "Huisnummer",
        zipCode: "PostCode",
        city: "Plaats",
        title3: "Welke auto betreft het?",
        brand: "Merk Bijv. Tesla of Volkswagen",
        model: "Model Bijv. Model 3 standard range",
      },
      questions: {
        one: {
          title:
            "Wil je het laadstation op paal of aan de wand gemonteerd hebben?",
          option: {
            title: "Kies een optie: ",
            option1: "Op paal",
            option2: "Aan de wand",
          },
        },
        two: {
          title:
            "Wil je een vaste kabel of een stopcontact aansluiting voor jouw eigen laadkabels?",
          option: {
            title: "Kies een optie: ",
            option1: "Vaste kabel",
            option2: "Stop contact versie",
          },
        },
        three: {
          title: "Wat is de waarde van jouw hoofdaansluiting?",
          option: {
            title: "Kies een optie: ",
            option1: "1x35",
            option2: "1x40",
            option3: "3x25",
            option4: "3x40",
            option5: "3x60",
            option6: "Anders",
          },
        },
        four: {
          title: "Heeft u zonnepanelen en wilt er gebruik van maken?",
          option: {
            title: "Kies een optie: ",
            option1: "Ja, en ik wil er graag gebruik van maken.",
            option2:
              "Ja, maar ik hoef niet per se een laadstation dat daar gebruik van maakt.",
            option3: "Nee, maar die komen nog wel.",
            option4: "Nee, en die komen ook niet.",
          },
        },
        five: {
          title:
            "Wat is de afstand tussen de meterkast en het gewenste punt van installatie?",
          option: {
            title: "Kies een optie: ",
            option1: "Tot 5 meter",
            option2: "Tussen 5 en 10 meter",
            option3: "Tussen 10 en 20 meter",
            option4: "Meer dan 20 meter",
          },
        },
        six: {
          title:
            "Is er een bruibare kruipruimte aanwezig? (Droog & beweegbaar)",
          option: {
            title: "Kies een optie: ",
            option1: "Ja",
            option2: "Nee",
          },
        },
        seven: {
          title: "Bevindt de meterkast zich tegen de buitenwand van het huis?",
          option: {
            title: "Kies een optie: ",
            option1: "Ja",
            option2: "Nee",
          },
        },
        eight: {
          title: "Moet er graaf / straatwerk verricht worden?",
          option: {
            title: "Kies een optie: ",
            option1: "Ja",
            option2: "Nee",
          },
        },
        nine: {
          titleOne: "Hoeveel meter graafwerk?",
          titleTwo: "Waarvan hoeveel meter straatwerk?",
          descOne: "Bijv. 2 meter",
          descTwo: "Bijv. 2 meter",
        },
        ten: {
          title:
            "Kunt u een omschrijving maken van het traject van de meterkast naar het gewenste installatiepunt?",
          description:
            "Is er een kruipruimte? Moet de stroomkabel een traject door bijvoorbeeld een schuur maken? Moet er graaf/straatwerk verricht worden?",
        },
      },
      submit: "Verstuur de aanvraag",
      succes: "De aanvraag is succesvol verstuurd",
      error: "Er is iets fout gegaan, probeer later opnieuw",
    },
  },
  {
    name: "UK",
    header: {
      option1: "About Us",
      option2: "Installations",
      option3: "Quotation",
    },
    hero: {
      heading: (
        <>
          Your partner for
          <span className="text-[#29C931]"> electric </span>
          charging solutions
        </>
      ),
    },
    partners: {
      title: "Our best choices",
    },
    solution: {
      title: "Charging Solutions for",
      solution1: {
        title: "Private/At Home",
        description:
          "Our electric car charging experts have selected charging boxes and accessories from renowned suppliers that are suitable for private homes for both hybrid and fully electric cars. Zelectric works closely with Wallbox, Charge Amps, Easee, and EV Box, who according to our own testing, offer the best charging boxes and accessories on the market for charging electric cars. Zelectric offers complete installation of charging boxes and charging stations by certified electricians. Our main focus is customer satisfaction and ensuring that our service is perfect!",
      },
      solution2: {
        title: "Company",
        description:
          "Zelectric has selected the most popular and charging boxes that are suitable for businesses. These charging box models from Wallbox, Easee, EvBox and Charge Amps work great for both hybrid and electric cars and are future proof for electric cars. Above all, it is important to keep in mind that if you are driving hybrid cars today, it is important to install a charging box that is also optimal for clean electric cars the next time you change cars. We offer companies a total solution with charging boxes and installation at a fixed price.",
      },
      solution3: {
        title: "Owners Association",
        description:
          "Charging boxes and charging solutions for companies and property owners. The energy conversion in the Netherlands to fossil-free is now underway. Businesses and property owners today need to switch and offer their employees and tenants charging options for hybrid and electric cars.",
      },
    },
    aboutCompany: {
      title: "Quality and sustainability are our priority",
      option1: {
        title: "About Us",
        description:
          "The basis of our business operations is a sustainable future. We are constantly looking for innovative ways to avoid waste and to ensure that we steer society in a more sustainable direction. Zelectric is the right choice for you if you highly value fast service, delivery, customer-friendliness and quality.",
      },
      option2: {
        title: "Our Mission",
        description:
          "Electromobility is the key to a fossil-free and pollution-free Earth's environment. With our knowledge, experience and awareness, we contribute to the transition to a sustainable future with emission-free electric vehicles. Some of our missions are rational use of energy resources by implementing green technology and advanced engineering solutions where possible.",
      },
      option3: {
        title: "Our Vision",
        description:
          "The European car industry is undergoing a technological revolution and is switching from fossil fuels to green electromobility. Zelectric wants to help support this transition to electrification and to a more sustainable environment by using the right business models and technical solutions.",
      },
    },
    process: {
      title: "This is how it works",
      point1: {
        title: "Consult our Powerpoint",
        description:
          "You can consult our powerpoint to make the installation process even easier",
      },
      point2: {
        title: "Place an order",
        description: "Choose one of our packages or let us choose for you!",
      },
      point3: {
        title: "We book an appointment for you",
        description: "We will contact you and book the installation",
      },
      point4: {
        title: "Installation",
        description:
          "Our installers will come by and install the box in consultation with you",
      },
    },
    contact: {
      title: "We will help you make the right choice!",
      option1: "Charging box that suits you",
      option2: "Deduction for green technology",
      option3: "Outstanding service!",
    },
    offerte: {
      title: "Installation request",
      heading: "Get advice from our experts",
      heading2:
        "We can be reached by phone from Monday to Sunday from 8:00 AM to 6:00 PM. We try to answer your WhatsApp messages, emails and phone calls as quickly as possible!",
      info: {
        title1: "Personal information",
        firstName: "First name",
        lastName: "Last name",
        email: "E-Mail",
        tel: "Phone number",
        title2: "Address information",
        companyName: "Company name",
        streetName: "Street name",
        houseNr: "House number",
        zipCode: "Postal code",
        city: "City",
        title3: "What model is your car?",
        brand: "Brand (For example Tesla or Volkswagen)",
        model: "Model (For example Tesla model 3 standard range)",
      },
      questions: {
        one: {
          title:
            "Do you want the charging station mounted on a pole or on the wall?",
          option: {
            title: "Choose an option: ",
            option1: "Pole",
            option2: "Wall",
          },
        },
        two: {
          title:
            "Do you want a fixed cable or a socket connection for your own charging cables?",
          option: {
            title: "Choose an option: ",
            option1: "Fixed cable",
            option2: "Socket connection",
          },
        },
        three: {
          title: "What is the value of your main connection?",
          option: {
            title: "Choose an option: ",
            option1: "1x35",
            option2: "1x40",
            option3: "3x25",
            option4: "3x40",
            option5: "3x60",
            option6: "Others",
          },
        },
        four: {
          title: "Do you have solar panels you would like to use?",
          option: {
            title: "Choose an option: ",
            option1: "Yes, and I would like to use them.",
            option2:
              "Yes, but I do not wish to use them for the charging station.",
            option3: "No, but I will have them in the future.",
            option4: "No, and I don’t plan on getting them.",
          },
        },
        five: {
          title:
            "What is the distance between the fuse box and the desired point of installation?",
          option: {
            title: "Choose an option: ",
            option1: "Up to 5 meters",
            option2: "Between 5 and 10 meters",
            option3: "Between 10 and 20 meters",
            option4: "More than 20 meters",
          },
        },
        six: {
          title: "Is there a usable crawl space? (Dry & movable)",
          option: {
            title: "Choose an option: ",
            option1: "Yes",
            option2: "No",
          },
        },
        seven: {
          title:
            "Is the fuse box located against the outside wall of the house?",
          option: {
            title: "Choose an option: ",
            option1: "Yes",
            option2: "No",
          },
        },
        eight: {
          title: "Does digging / street work have to be done?",
          option: {
            title: "Choose an option: ",
            option1: "Yes",
            option2: "No",
          },
        },
        nine: {
          titleOne: "How many digging meters",
          titleTwo: "And how much of that is streetwork?",
          descOne: "Ex. 2 meters",
          descTwo: "Ex. 2 meters",
        },
        ten: {
          title:
            "Can you describe the route from the meter box to the desired installation point?",
          description:
            "Is there a crawl space? Does the power cable have to make a route through, for example, a shed? Does digging/street work have to be done?",
        },
      },
      submit: "Send Request",
      succes: "Quotation request is sent successfully",
      error: "An error has occurred, try later again",
    },
  },
];

function App() {
  const [selectedLang, setSelectedLang] = useState({ name: "NL", flag: Nl });

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Main
              lang={webTranslation.find(
                (translation) => translation.name === selectedLang.name
              )}
              selectedLang={selectedLang}
              setLang={setSelectedLang}
            />
          }
        />
        <Route
          exact
          path="/offerte"
          element={
            <Offerte
              lang={webTranslation.find(
                (translation) => translation.name === selectedLang.name
              )}
              selectedLang={selectedLang}
              setLang={setSelectedLang}
            />
          }
        />
        <Route
          path="*"
          element={<Navigate to="/" replace lang={selectedLang} />}
        />
      </Routes>
    </Router>
  );
}

const Main = ({ lang, selectedLang, setLang }) => {
  // console.log(lang);
  const refList = [];

  const handleClick = (refIdx) => {
    refList.forEach((e, idx) => {
      if (idx === refIdx) {
        const y = e?.getBoundingClientRect().top + window.scrollY - 122;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    });
  };

  return (
    <div className="font-sans">
      <Hero
        refList={refList}
        handleClick={handleClick}
        langs={lang}
        setLang={setLang}
        selectedLang={selectedLang}
      />
      <Partners lang={lang.partners} />
      <Solution lang={lang.solution} />
      <AboutCompany refList={refList} lang={lang.aboutCompany} />
      <Process refList={refList} lang={lang.process} />
      <Contact lang={lang.contact} />
      <Footer handleClick={handleClick} />
      {/* <p className="text-center">Kucharski Enterprise™ 2022</p> */}
    </div>
  );
};

const Offerte = ({ company, lang, selectedLang, setLang }) => {
  const refList = [];
  const handleClick = (refIdx) => {
    refList.forEach((e, idx) => {
      if (idx === refIdx) e?.scrollIntoView({ behavior: "smooth" });
    });
  };

  // Scroll to top of the page when entering Offerte
  window.scrollTo(0, 0);

  return (
    <div className="font-sans overflow-x-clip">
      <Header
        refList={refList}
        handleClick={handleClick}
        lang={lang.header}
        setLang={setLang}
        selectedLang={selectedLang}
      />
      <ContactForm refList={refList} company={company} lang={lang.offerte} />
      <Footer handleClick={handleClick} />
    </div>
  );
};

export default App;
