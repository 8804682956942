import { useState } from "react";
import Logo from "../../assets/image/logo-horizontal.svg";
import LogoBox from "../../assets/image/logo-box.svg";
import Uk from "../../assets/image/icons/Uk.svg";
import Nl from "../../assets/image/icons/Nl.svg";
import { useNavigate, useLocation } from "react-router";

function Header({
  lang,
  selectedLang,
  setLang,
  refList = null,
  handleClick = null,
}) {
  const navigation = [
    { type: "href", name: lang.option1, href: "/", divId: 1 },
    { type: "href", name: lang.option2, href: "/", divId: 2 },
    { type: "href", name: lang.option3, href: "/offerte" },
    {
      type: "langSelect",
      options: [
        { name: "NL", flag: Nl },
        { name: "UK", flag: Uk },
      ],
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLangSelect = () => {
    // console.log(selectedLang);
    // console.log("Click!");
    if (selectedLang.name === navigation[3].options[0].name)
      setLang(navigation[3].options[1]);
    else setLang(navigation[3].options[0]);
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className="fixed w-full h-screen z-50"
        />
      )}

      <div
        ref={(ref) => {
          if (refList !== null) refList[0] = ref;
        }}
        className="z-50 w-screen sm:w-full py-5 bg-black fixed top-0 overflow-hidden"
      >
        <div className="flex items-center justify-between">
          <img
            onClick={() => navigate("/")}
            className="ml-2 lg:hidden drop-shadow-md cursor-pointer transition ease-in-out duration-200 hover:scale-110"
            href="#"
            width={60}
            alt="Zelectric Logo"
            src={LogoBox}
          />
          <img
            onClick={() => navigate("/")}
            className="hidden lg:block lg:ml-12 drop-shadow-md cursor-pointer transition ease-in-out duration-200 hover:scale-110"
            href="#"
            width={313}
            alt="Zelectric Logo"
            src={Logo}
          />
          <div className="hidden w-3/4 lg:w-1/2 lg:max-w-[500px] lg:flex align-middle items-center justify-evenly">
            {navigation.map((nav) =>
              nav.type === "href" ? (
                <button
                  key={nav.name}
                  onClick={() => {
                    if (location.pathname === "/" && nav.href === "/")
                      handleClick(nav.divId);
                    else if (nav.href !== "/") navigate(nav.href);
                    else navigate("/");
                  }}
                  className="btn text-sm lg:text-xl drop-shadow-md bg-gray hover:bg-green hover:text-black p-2 sm:p-3 lg:min-w-[104px] text-white rounded-md cursor-pointer transition ease-in-out duration-200 hover:scale-110"
                >
                  {nav.name}
                </button>
              ) : (
                <div
                  key={nav.type}
                  onClick={() => handleLangSelect()}
                  className="cursor-pointer"
                >
                  <img
                    className="scale-150"
                    key={nav.type}
                    alt={
                      nav.options.filter(
                        (option) => option.name !== selectedLang.name
                      )[0].name
                    }
                    src={
                      nav.options.filter(
                        (option) => option.name !== selectedLang.name
                      )[0].flag
                    }
                  />
                </div>
              )
            )}
          </div>

          <div
            onClick={() => setIsOpen(!isOpen)}
            className="mr-2 lg:hidden w-8 h-8 cursor-pointer"
          >
            {isOpen ? (
              <svg className="w-8 h-8">
                <line
                  x1={0}
                  x2={32}
                  y1={0}
                  y2={32}
                  stroke="#fff"
                  strokeWidth={5}
                />
                <line
                  x1={0}
                  x2={32}
                  y1={32}
                  y2={0}
                  stroke="#fff"
                  strokeWidth={5}
                />
              </svg>
            ) : (
              <svg className="w-8 h-8">
                <line
                  x1={0}
                  x2={32}
                  y1={5}
                  y2={5}
                  stroke="#fff"
                  strokeWidth={5}
                />
                <line
                  x1={0}
                  x2={32}
                  y1={15}
                  y2={15}
                  stroke="#fff"
                  strokeWidth={5}
                />
                <line
                  x1={0}
                  x2={32}
                  y1={25}
                  y2={25}
                  stroke="#fff"
                  strokeWidth={5}
                />
              </svg>
            )}
          </div>
        </div>

        {isOpen && (
          <>
            <div className="bottom-0 flex flex-col items-center text-center h-40 justify-evenly">
              {navigation.map((nav) => {
                switch (nav.type) {
                  case "href":
                    return <p key={nav.name}>{nav.name}</p>;
                  case "langSelect":
                    return (
                      <div
                        onClick={() => handleLangSelect()}
                        className="cursor-pointer"
                      >
                        <img
                          className="scale-150"
                          key={nav.type}
                          alt={
                            nav.options.filter(
                              (option) => option.name !== selectedLang.name
                            )[0].name
                          }
                          src={
                            nav.options.filter(
                              (option) => option.name !== selectedLang.name
                            )[0].flag
                          }
                        />
                      </div>
                    );
                  default:
                    return <></>;
                }
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Header;
