import React, { useState, useRef } from "react";
import { SelectOption } from "../SelectOption/SelectOption";
import emailjs from "@emailjs/browser";

/*

Emails keys

from_voornaam
from_email
from_achternaam
from_tel
from_company
from_address
from_zipCode
from_houseNr
from_city
from_carBrand
from_carModel
from_option1
from_option2
from_option3
from_option3Other
from_option4
from_option5
from_option6
from_option7
from_option8
from_option8_1
from_option8_2
from_message
*/

export const ContactForm = ({ refList, lang }) => {
  // console.log(lang);
  const formOptions = [
    [
      { id: 0, text: lang.questions.one.option.title, valid: false },
      { id: 1, text: lang.questions.one.option.option1, valid: true },
      { id: 2, text: lang.questions.one.option.option2, valid: true },
    ],
    [
      { id: 0, text: lang.questions.two.option.title, valid: false },
      { id: 1, text: lang.questions.two.option.option1, valid: true },
      { id: 2, text: lang.questions.two.option.option2, valid: true },
    ],
    [
      { id: 0, text: lang.questions.three.option.title, valid: false },
      { id: 1, text: lang.questions.three.option.option1, valid: true },
      { id: 2, text: lang.questions.three.option.option2, valid: true },
      { id: 3, text: lang.questions.three.option.option3, valid: true },
      { id: 4, text: lang.questions.three.option.option4, valid: true },
      { id: 5, text: lang.questions.three.option.option5, valid: true },
      {
        id: 6,
        text: lang.questions.three.option.option6,
        valid: true,
        value: true,
      },
    ],
    [
      { id: 0, text: lang.questions.four.option.title, valid: false },
      { id: 1, text: lang.questions.four.option.option1, valid: true },
      {
        id: 2,
        text: lang.questions.four.option.option2,
        valid: true,
      },
      { id: 3, text: lang.questions.four.option.option3, valid: true },
      { id: 4, text: lang.questions.four.option.option4, valid: true },
    ],
    [
      { id: 0, text: lang.questions.five.option.title, valid: false },
      { id: 1, text: lang.questions.five.option.option1, valid: true },
      { id: 2, text: lang.questions.five.option.option2, valid: true },
      { id: 3, text: lang.questions.five.option.option3, valid: true },
      { id: 4, text: lang.questions.five.option.option4, valid: true },
    ],
    [
      { id: 0, text: lang.questions.six.option.title, valid: false },
      { id: 1, text: lang.questions.six.option.option1, valid: true },
      { id: 2, text: lang.questions.six.option.option2, valid: true },
    ],
    [
      { id: 0, text: lang.questions.seven.option.title, valid: false },
      { id: 1, text: lang.questions.seven.option.option1, valid: true },
      { id: 2, text: lang.questions.seven.option.option2, valid: true },
    ],
    [
      { id: 0, text: lang.questions.eight.option.title, valid: false },
      {
        id: 1,
        text: lang.questions.eight.option.option1,
        valid: true,
        value: true,
      },
      {
        id: 2,
        text: lang.questions.eight.option.option2,
        valid: true,
        value: false,
      },
    ],
  ];

  let form = useRef();

  const [isSuccesfull, setIsSuccesfull] = useState(false);
  const [isError, setIsError] = useState(false);

  const [selectedOption1, setSelectedOption1] = useState(formOptions[0][0]);
  const [selectedOption2, setSelectedOption2] = useState(formOptions[1][0]);
  const [selectedOption3, setSelectedOption3] = useState(formOptions[2][0]);
  const [selectedOption4, setSelectedOption4] = useState(formOptions[3][0]);
  const [selectedOption5, setSelectedOption5] = useState(formOptions[4][0]);
  const [selectedOption6, setSelectedOption6] = useState(formOptions[5][0]);
  const [selectedOption7, setSelectedOption7] = useState(formOptions[6][0]);
  const [selectedOption8, setSelectedOption8] = useState(formOptions[7][0]);

  const handleClick = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yixf4po",
        "template_jdku1tc",
        form.current,
        "tckclIMHMFXejCNDj"
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsSuccesfull(true);
          }
          // console.log("SUCCES!", res.status, res.text);
        },
        (error) => {
          setIsError(true);
        }
      );
  };

  return (
    <div className="w-screen min-h-screen">
      <div className="pt-40">
        <div className="lg:w-10/12 mx-auto p-10 space-y-10 bg-zinc-900 rounded drop-shadow mb-20">
          {/* Info Div */}
          <div className="lg:w-10/12 mx-auto">
            <h2 className="font-bold text-4xl">{lang.title}</h2>
            <h3 className="text-lg">{lang.heading}</h3>
            <h3 className="text-sm">{lang.heading2}</h3>
            <div className="py-2 flex flex-col space-y-2 max-w-[215px] lg:space-y-0 lg:min-w-[350px] lg:flex-row lg:w-1/3 justify-between">
              <button className="px-4 py-2 bg-green rounded drop-shadow hover:bg-greenDark transition ease-in-out duration-200 hover:scale-110">
                +31 0625456271
              </button>
              <button
                onClick={() => window.open("mailto:offer@zelectric.nl")}
                className="px-4 py-2 bg-zinc-800 rounded drop-shadow hover:bg-zinc-700 transition ease-in-out duration-200 hover:scale-110"
              >
                offer@zelectric.nl
              </button>
            </div>
          </div>

          <form
            ref={(ref) => (form.current = ref)}
            onSubmit={handleClick}
            className="flex-col space-y-10"
          >
            <div className="flex flex-col space-y-2 lg:space-y-4 ">
              <label className="font-bold text-center drop-shadow">
                {lang.info.title1}
              </label>
              <div className="flex flex-col lg:flex-row justify-around">
                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.firstName} *`}
                  name="from_voornaam"
                  required
                />
                <input
                  type="email"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.email} *`}
                  name="from_email"
                  required
                />
              </div>

              <div className="flex flex-col lg:flex-row justify-around">
                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.lastName} *`}
                  name="from_achternaam"
                  required
                />
                <input
                  type="tel"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.tel} *`}
                  name="from_tel"
                  required
                />
              </div>
            </div>

            <div className="flex flex-col lg:space-y-4 ">
              <label className="font-bold text-center drop-shadow">
                {lang.info.title2}
              </label>

              <input
                type="text"
                className="kk-input-form lg:mx-auto lg:w-1/3 drop-shadow"
                placeholder={`${lang.info.companyName}`}
                name="from_company"
              />

              <div className="flex flex-col lg:flex-row justify-around">
                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.streetName} *`}
                  name="from_address"
                  required
                />
                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.zipCode} *`}
                  name="from_zipCode"
                  required
                />
              </div>
              <div className="flex flex-col lg:flex-row justify-around">
                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.houseNr} *`}
                  name="from_houseNr"
                  required
                />
                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.city} *`}
                  name="from_city"
                  required
                />
              </div>
            </div>

            <div className="flex flex-col">
              <label className="font-bold text-center">
                {lang.info.title3}
              </label>

              <div className="flex flex-col lg:flex-row justify-around">
                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.brand}`}
                  name="from_carBrand"
                />

                <input
                  type="text"
                  className="kk-input-form lg:w-1/3 drop-shadow"
                  placeholder={`${lang.info.model}`}
                  name="from_carModel"
                />
              </div>
            </div>

            <div className="flex flex-col space-y-8 lg:w-10/12 mx-auto">
              <SelectOption
                label={lang.questions.one.title}
                options={formOptions[0]}
                selectedOption={selectedOption1}
                setSelectedOption={setSelectedOption1}
                emailTag="from_option1"
              />

              <SelectOption
                label={lang.questions.two.title}
                options={formOptions[1]}
                selectedOption={selectedOption2}
                setSelectedOption={setSelectedOption2}
                emailTag="from_option2"
              />

              <SelectOption
                label={lang.questions.three.title}
                options={formOptions[2]}
                selectedOption={selectedOption3}
                setSelectedOption={setSelectedOption3}
                emailTag="from_option3"
              />

              {selectedOption3?.valid && selectedOption3?.value ? (
                <>
                  <input
                    type="text"
                    className="kk-input-form lg:w-1/3"
                    placeholder="..."
                    name="from_option3Other"
                  />
                </>
              ) : (
                <></>
              )}

              <SelectOption
                label={lang.questions.four.title}
                options={formOptions[3]}
                selectedOption={selectedOption4}
                setSelectedOption={setSelectedOption4}
                emailTag="from_option4"
              />

              <SelectOption
                label={lang.questions.five.title}
                options={formOptions[4]}
                selectedOption={selectedOption5}
                setSelectedOption={setSelectedOption5}
                emailTag="from_option5"
              />

              <SelectOption
                label={lang.questions.six.title}
                options={formOptions[5]}
                selectedOption={selectedOption6}
                setSelectedOption={setSelectedOption6}
                emailTag="from_option6"
              />

              <SelectOption
                label={lang.questions.seven.title}
                options={formOptions[6]}
                selectedOption={selectedOption7}
                setSelectedOption={setSelectedOption7}
                emailTag="from_option7"
              />

              <SelectOption
                label={lang.questions.eight.title}
                options={formOptions[7]}
                selectedOption={selectedOption8}
                setSelectedOption={setSelectedOption8}
                emailTag="from_option8"
              />
              {/* IF yes */}
              {selectedOption8?.value ? (
                <div className="flex justify-between">
                  <div className="flex flex-col lg:w-1/3">
                    <label>{lang.questions.nine.titleOne}</label>
                    <input
                      type="text"
                      className="kk-input-form"
                      placeholder={lang.questions.nine.descOne}
                      name="from_option8_1"
                      required
                    />
                  </div>

                  <div className="flex flex-col lg:w-1/3">
                    <label>{lang.questions.nine.titleTwo}</label>
                    <input
                      type="text"
                      className="kk-input-form "
                      placeholder={lang.questions.nine.descTwo}
                      name="from_option8_2"
                      required
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}

              <label>{lang.questions.ten.title}</label>
              <textarea
                type="text"
                className="drop-shadow bg-zinc-800 resize-none h-48 rounded border-0 focus:outline-none focus:border-transparent focus:ring-green-500 focus:transition focus:duration-150 focus:ease-in-out"
                placeholder={lang.questions.ten.description}
                name="from_message"
              />

              <button
                type="submit"
                className="w-1/2 lg:w-1/4 mt-5 lg:mt-10 mx-auto text-l font-bold px-5 text-center drop-shadow-md rounded-2xl bg-green hover:bg-greenDark text-white p-2 transition ease-in-out duration-200 hover:scale-110"
              >
                {lang.submit}
              </button>

              {isSuccesfull && (
                <p className="mx-auto text-green">{lang.success}</p>
              )}
              {isError && <p className="mx-auto text-red-500">{lang.error}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
