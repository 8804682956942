import React, { useEffect, useState } from "react";
import carChargingVideo from "../../assets/video/car_charging.mp4";
import { LightningBoltIcon } from "@heroicons/react/solid";
import Header from "../Header/Header";

const Hero = ({ refList, handleClick, langs, setLang, selectedLang }) => {
  const lang = langs.hero;

  const [batteryLevel, setBatteryLevel] = useState(0);

  useEffect(() => {
    let timeout = null;
    const timer = setInterval(() => {
      if (batteryLevel < 100) {
        setBatteryLevel(batteryLevel + 2);
      } else {
        timeout = setTimeout(() => {
          setBatteryLevel(0);
        }, 2000);
      }
    }, 200);

    return () => {
      clearInterval(timer);
      clearTimeout(timeout);
    };
  });

  return (
    <>
      <Header
        refList={refList}
        handleClick={handleClick}
        lang={langs.header}
        setLang={setLang}
        selectedLang={selectedLang}
      />
      <div className="bg-[#18181B] w-screen min-h-screen relative flex">
        <div className="w-full h-full flex flex-col z-30 drop-shadow-md my-auto">
          <div className="max-w-[630px] text-2xl sm:text-4xl md:text-6xl lg:text-6xl text-center w-4/5 mx-auto font-bold text-white">
            {lang.heading}
          </div>
        </div>
        <div className="w-screen relative">
          <div className="w-full h-full bg-[#29C931] z-10 top-0 right-0 absolute opacity-30"></div>
          <video
            autoPlay
            muted
            loop
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "100vh",
              width: "100%",
              objectFit: "cover",
            }}
          >
            <source src={carChargingVideo} type="video/mp4" />
          </video>
          <div className="absolute z-20 bottom-10 right-10 border-2 w-10 h-20 rounded bg-[#18181B] border-[#18181B] drop-shadow">
            <div className="relative -top-2 border-2 border-[#18181B] w-4 h-3 p-0 m-0 rounded mx-auto bg-[#18181B] drop-shadow" />
            <div
              className={`absolute bottom-0 m-0 p-0 ${
                batteryLevel < 20 ? "bg-red-500" : ""
              } ${
                batteryLevel < 50 && batteryLevel > 20 ? "bg-yellow-400" : ""
              } ${
                batteryLevel > 50 ? "bg-[#29C931]" : ""
              } w-full rounded ease-in-out duration-100`}
              style={{ height: `${batteryLevel}%` }}
            />
            <LightningBoltIcon
              className={`${
                batteryLevel >= 100 ? "hidden" : ""
              }  animate-pulse absolute z-40 w-full ease-in-out duration-200 transition`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
