import React, { useRef, useState } from "react";
import { ReactComponent as Houses } from "../../assets/image/illustrations/houses-animate.svg";
import { ReactComponent as Company } from "../../assets/image/illustrations/company-animate.svg";
import { ReactComponent as Building } from "../../assets/image/illustrations/building-animate.svg";
import useOnScreen from "../../hooks/useOnScreen";
import { isMobile } from "react-device-detect";

export const Solution = ({ lang }) => {
  const [firstActivated, setFirstActivated] = useState(false);
  const [secondActivated, setSecondActivated] = useState(false);
  const [thirdActivated, setThirdActivated] = useState(false);

  const refHome = useRef();
  const isVisibleHome = useOnScreen(refHome);
  const refCompany = useRef();
  const isVisibleCompany = useOnScreen(refCompany);
  const refCompanyMobile = useRef();
  const isVisibleCompanyMobile = useOnScreen(refCompanyMobile);
  const refBuilding = useRef();
  const isVisibleBuilding = useOnScreen(refBuilding);

  return (
    <div className="bg-gray pb-10 lg:pb-20 space-y-5 lg:space-y-28">
      <h2 className="text-xl lg:text-5xl text-center pt-10 font-bold">
        {lang.title}
      </h2>
      <div className="w-11/12 lg:w-4/5 flex flex-col lg:flex-row justify-center mx-auto">
        {/* Illustration */}

        <div ref={refHome} className="w-full md:w-[330px] mx-auto lg:mx-0">
          {isMobile ? (
            firstActivated === false ? (
              isVisibleHome &&
              (setFirstActivated(true), (<Houses style={{ width: "100%" }} />))
            ) : (
              <Houses style={{ width: "100%" }} />
            )
          ) : (
            isVisibleHome && <Houses style={{ width: "100%" }} />
          )}
        </div>

        <div className="flex flex-col justify-between">
          <h3 className="font-bold text-lg lg:text-3xl">
            {lang.solution1.title}
          </h3>
          <p className="text-base lg:text-2xl font-normal max-w-[820px]">
            {lang.solution1.description}
          </p>
        </div>
      </div>

      <div className="w-11/12 lg:w-4/5 flex flex-col lg:flex-row justify-center mx-auto">
        <div
          ref={refCompanyMobile}
          className="lg:hidden w-full h-full md:w-[330px] mx-auto"
        >
          {secondActivated === false ? (
            isVisibleCompanyMobile &&
            (setSecondActivated(true), (<Company style={{ width: "100%" }} />))
          ) : (
            <Company style={{ width: "100%" }} />
          )}
        </div>
        <div className="flex flex-col justify-between">
          <h3 className="font-bold text-lg lg:text-3xl">
            {lang.solution2.title}
          </h3>
          <p className="text-base lg:text-2xl font-normal max-w-[820px]">
            {lang.solution2.description}
          </p>
        </div>

        {/* Illustration */}
        <div
          ref={refCompany}
          className="hidden lg:block w-[330px] mx-auto lg:mx-0"
        >
          {isVisibleCompany && <Company style={{ width: "100%" }} />}
        </div>
      </div>

      <div className="w-11/12 lg:w-4/5 md:h-[330px] flex flex-col lg:flex-row justify-center mx-auto">
        {/* Illustration */}
        <div ref={refBuilding} className="w-full md:w-[330px] mx-auto lg:mx-0">
          {isMobile ? (
            thirdActivated === false ? (
              isVisibleBuilding &&
              (setThirdActivated(true),
              (<Building style={{ width: "100%" }} />))
            ) : (
              <Building style={{ width: "100%" }} />
            )
          ) : (
            isVisibleBuilding && <Building style={{ width: "100%" }} />
          )}
        </div>

        <div className="flex flex-col justify-center">
          <h3 className="font-bold text-lg lg:text-3xl">
            {lang.solution3.title}
          </h3>
          <p className="text-base lg:text-2xl font-normal max-w-[820px]">
            {lang.solution3.description}
          </p>
        </div>
      </div>
    </div>
  );
};
