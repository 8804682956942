import React, { useState } from "react";
import { ReactComponent as People } from "../../assets/image/icons/people.svg";
import { ReactComponent as Target } from "../../assets/image/icons/target.svg";
import { ReactComponent as Vision } from "../../assets/image/icons/vision.svg";
import { ReactComponent as Team } from "../../assets/image/illustrations/team-animate.svg";
import { ReactComponent as Goal } from "../../assets/image/illustrations/goal-animate.svg";
import { ReactComponent as LightBulb } from "../../assets/image/illustrations/light-bulb-animate.svg";

export const AboutCompany = ({ refList, lang }) => {
  const Options = [
    {
      id: 1,
      iconText: lang.option1.title,
      illustartion: <Team style={{ width: "100%" }} />,
      title: "Wie zijn wij?",
      description: lang.option1.description,
    },
    {
      id: 2,
      iconText: lang.option2.title,
      illustartion: <Goal style={{ width: "100%" }} />,
      title: "Wat doen wij?",
      description: lang.option2.description,
    },
    {
      id: 3,
      iconText: lang.option3.title,
      illustartion: <LightBulb style={{ width: "100%" }} />,
      title: "De toekomst",
      description: lang.option3.description,
    },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const menuClickHandler = (option) => {
    var currOption = selectedOption;
    // console.log(option);

    if (option?.id === currOption?.id) {
      setSelectedOption(null);
      // console.log("Set to null");
    } else {
      setSelectedOption(option);
      // console.log("Set to something");
    }
  };

  return (
    <div
      ref={(ref) => {
        refList[1] = ref;
      }}
    >
      <div className="bg-green w-screen flex h-[120px] lg:h-[400px] mx-0 ">
        <div className="w-full relative h-full flex mx-auto overflow-hidden">
          <div className="font-bold text-left ml-2 lg:ml-10 my-auto text-xs lg:text-4xl 2xl:text-5xl w-1/5 lg:max-w-[386px] drop-shadow-md">
            {lang.title}
          </div>

          <div className="flex z-10 justify-end absolute right-0 h-full w-2/3">
            <div className="w-5/12 lg:w-1/4">
              {/* Shape */}
              <div
                onClick={() => menuClickHandler(Options[0])}
                className={`border-l-2 ${
                  selectedOption?.id === Options[0].id ? "bg-black" : "bg-green"
                } border-[#2DAF34]  w-full h-full -skew-x-12 hover:bg-black hover:border-black hover:cursor-pointer`}

              >
                {/* Container inside Shape */}
                <div className="skew-x-12 h-full w-9/12 mx-auto flex flex-col items-center justify-center text-center">
                  <People className="w-2/3 h-1/2" />
                  <span className="text-sm font-bold">
                    {lang.option1.title}
                  </span>
                </div>
              </div>
            </div>

            <div className="w-5/12 lg:w-1/4">
              {/* Shape */}
              <div
                onClick={() => menuClickHandler(Options[1])}
                className={`border-l-2 border-r-2 border-[#2DAF34] ${
                  selectedOption?.id === Options[1].id ? "bg-black" : "bg-green"
                } left-10 w-full h-full -skew-x-12 hover:bg-black hover:border-black hover:cursor-pointer`}
              >
                {/* Container inside Shape */}
                <div className="skew-x-12 h-full w-9/12 mx-auto flex flex-col items-center justify-center text-center">
                  <Target className="w-2/3 h-1/2" />
                  <span className="text-sm font-bold">
                    {lang.option2.title}
                  </span>
                </div>
              </div>
            </div>

            <div className="w-1/3 lg:w-1/4">
              {/* Shape */}
              <div
                onClick={() => menuClickHandler(Options[2])}
                className={`border-r-2 border-[#2DAF34] ${
                  selectedOption?.id === Options[2].id ? "bg-black" : "bg-green"
                } left-10 w-full h-full -skew-x-12 hover:bg-black hover:border-black hover:cursor-pointer`}
              >
                {/* Container inside Shape */}
                <div className="skew-x-12 h-full w-9/12 mx-auto flex flex-col items-center justify-center text-center">
                  <Vision className="w-2/3 h-1/2" />
                  <span className="text-sm font-bold">
                    {lang.option3.title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedOption != null && (
        <div className="flex flex-col lg:flex-row justify-around items-center py-10 max-w-[1500px] mx-auto">
          <div className="w-full lg:w-1/5">{selectedOption.illustartion}</div>
          <p className="text-xl text-justify w-11/12 lg:w-3/4">
            {
              Options.find((option) => option.id === selectedOption.id)
                .description
            }
          </p>
        </div>
      )}
    </div>
  );
};
