import React from "react";
import { Link } from "react-router-dom";
import { DocumentTextIcon } from "@heroicons/react/outline";
import { PhoneIcon, KeyIcon } from "@heroicons/react/solid";
import { ReactComponent as Powerpoint } from "../../assets/image/icons/powerpoint.svg";
import installatie from "../../assets/pdf/Installatie.pdf";

function Process({ refList, lang }) {
  return (
    <div
      ref={(ref) => {
        refList[2] = ref;
      }}
      className="bg-zinc-900 pt-12 pb-12 flex flex-col items-center"
    >
      <h2 className="mb-12 font-bold text-2xl lg:text-5xl">{lang.title}</h2>

      <div className="flex flex-col w-2/3 items-start space-y-10 lg:space-y-0 lg:flex-row lg:justify-evenly lg:align-middle">
        <div className="w-full lg:w-1/5 flex justify-evenly lg:flex-col items-center text-center">
          <Link to={installatie} target="_blank" rel="noopener noreferrer">
            <div className="ml-2 border-2 border-zinc-800 rounded-full w-[80px] h-[80px] hover:border-zinc-700 hover:cursor-pointer">
              <Powerpoint className="mx-auto my-auto h-full text-green scale-125" />{" "}
            </div>
          </Link>

          <div className="flex flex-col items-center w-3/5">
            <h3 className="font-bold text-base lg:text-xl mt-3">
              {lang.point1.title}
            </h3>
            <h4 className="text-sm text-center mt-3 text-gray-400">
              {lang.point1.description}
            </h4>
          </div>
        </div>
        <div className="w-full lg:w-1/5 flex justify-evenly lg:flex-col items-center text-center">
          <div className="ml-2 border-2 border-zinc-800 rounded-full w-[80px] h-[80px] hover:border-zinc-700">
            <DocumentTextIcon className="text-green scale-75" />{" "}
          </div>
          <div className="flex flex-col items-center w-3/5">
            <h3 className="font-bold text-base lg:text-xl mt-3">
              {lang.point2.title}
            </h3>
            <h4 className="text-sm text-center mt-3 text-gray-400">
              {lang.point2.description}
            </h4>
          </div>
        </div>

        <div className="w-full lg:w-1/5 flex justify-evenly lg:flex-col items-center text-center">
          <div className="ml-2 border-2 border-zinc-800 rounded-full w-[80px] h-[80px]  hover:border-zinc-700   ">
            <PhoneIcon className=" text-green scale-75" />{" "}
          </div>
          <div className="flex flex-col items-center w-3/5">
            <h3 className="font-bold text-base lg:text-xl mt-3">
              {lang.point3.title}
            </h3>
            <h4 className="text-sm text-center mt-3 text-gray-400">
              {lang.point3.description}
            </h4>
          </div>
        </div>

        <div className="w-full lg:w-1/5 flex justify-evenly lg:flex-col items-center text-center">
          <div className="ml-2 border-2 border-zinc-800 rounded-full w-[80px] h-[80px] hover:border-zinc-700">
            <KeyIcon className="text-green scale-75" />{" "}
          </div>
          <div className="flex flex-col items-center w-3/5">
            <h3 className="font-bold text-base lg:text-xl mt-3">
              {lang.point4.title}
            </h3>
            <h4 className="text-sm text-center mt-3 text-gray-400">
              {lang.point4.description}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
