import React from "react";
import { ReactComponent as ChargeAmpsLogo } from "../../assets/image/charge-amps-logo.svg";
import { ReactComponent as EaseeLogo } from "../../assets/image/easee-logo.svg";
import { ReactComponent as EvboxLogo } from "../../assets/image/evbox-logo.svg";
import { ReactComponent as WallboxLogo } from "../../assets/image/wallbox-logo.svg";

export const Partners = ({ lang }) => {
  return (
    <div className="w-full flex flex-col">
      <h2 className="font-bold text-xl lg:text-5xl text-center pt-10">
        {lang.title}
      </h2>
      <div className="w-full flex flex-row justify-evenly py-10 ">
        <EaseeLogo
          onClick={() => window.open("https://easee.com/", "_blank")}
          className="w-1/5 fill-[#4E4E4E] hover:fill-[#fff] hover:cursor-pointer hover:scale-110 transition duration-150"
        />
        <ChargeAmpsLogo
          onClick={() =>
            window.open("https://chargeamps.com/products/", "_blank")
          }
          className="w-1/5 fill-[#4E4E4E] hover:fill-[#fff] hover:cursor-pointer hover:scale-110 transition duration-150"
        />
        <WallboxLogo
          onClick={() =>
            window.open("https://wallbox.com/nl_nl/opladers", "_blank")
          }
          className="w-1/5 fill-[#4E4E4E] hover:fill-[#fff] hover:cursor-pointer hover:scale-110 transition duration-150"
        />
        <EvboxLogo
          onClick={() =>
            window.open("https://evbox.com/en/all-products", "_blank")
          }
          className="w-1/5 fill-[#4E4E4E] hover:fill-[#4cc7ee] hover:cursor-pointer hover:scale-110 transition duration-150"
        />
      </div>
    </div>
  );
};
