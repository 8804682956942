import React from "react";
import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";

const Contact = ({ lang }) => {
  return (
    <div className="pt-12 pb-12 flex flex-col items-center w-full ">
      <h2 className="text-lg font-bold lg:text-2xl mb-2">{lang.title}</h2>
      <div className="flex flex-col lg:flex-row lg:w-1/2">
        <div className="mx-auto lg:w-1/2 flex-col mt-6 my-auto">
          <div className="flex flex-row mb-2">
            <CheckIcon className="w-[25px] text-green" />
            <h4 className="text-gray-400 text-sm lg:text-base">
              {lang.option1}
            </h4>
          </div>

          <div className="flex flex-row mb-2">
            <CheckIcon className="w-[25px] text-green" />
            <h4 className="text-gray-400 text-sm lg:text-base">
              {lang.option2}
            </h4>
          </div>

          <div className="flex flex-row mb-2">
            <CheckIcon className="w-[25px] text-green" />
            <h4 className="text-gray-400 text-sm lg:text-base">
              {lang.option3}
            </h4>
          </div>

          <Link to={"/offerte"}>
            <button className="mx-auto w-full drop-shadow-md bottom-10 bg-green hover:bg-[#2DAF34] p-3 text-white rounded-md transition ease-in-out duration-200 hover:scale-110">
              Contact
            </button>
          </Link>
        </div>
        {/* <img alt="" src={Employee} className="w-1/2 lg:w-[20%] mx-auto" /> */}
      </div>
    </div>
  );
};

export default Contact;
