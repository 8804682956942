import React from "react";

export const SelectOption = ({
  label,
  options,
  selectedOption,
  setSelectedOption,
  emailTag,
}) => {
  return (
    <div className="flex flex-col space-y-2">
      {" "}
      <label>{label}</label>
      <select
        name={emailTag}
        onChange={({ target }) => {
          // console.log(options[target.selectedIndex]);
          setSelectedOption(options[target.selectedIndex]);
        }}
        className={`${
          selectedOption !== options[0] ? "text-white" : "text-gray"
        }  bg-green focus:ring-0 focus:outline-none border-0 drop-shadow`}
      >
        {options.map((option) => (
          <option
            className={`${
              option.id === 0 ? "text-gray" : "text-white"
            } hover:cursor-pointer`}
            value={option.text}
            key={option.id}
            // name={option.id === selectedOption.id ? emailTag : ""}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};
